import { makeObservable, action, runInAction, computed, observable } from 'mobx';

import _isNil from 'lodash/isNil';

import {
	type App,
	type ILeaderboardListResponse,
	type ICRMLeaderboardActiveResponse,
	type ICRMTextBlockResponse,
} from 'app';

import { LEADERBOARD_TABLE } from 'src/router/routes';

import { TLexicalContent } from 'src/components/typography/text-block/lexical';

import { generatePath } from 'src/utils/generate-path';

import { LeaderboardsGiftsModel, type ILeaderboardGift } from './gifts';

export interface ILeaderboardParticipantEntry {
	ID: {
		ID: string;
		Platform: 'twitch';
	};
	Slug: string;
	Position: number;
	Score?: number;
	Requester: boolean;
}

export interface ILeaderboardParticipant {
	username: string;
	position: number;
	score: number;
	isRequestedUser: boolean;
}

export interface ILeaderboardCurrent {
	organizationId: string;
	resourceId: string;
}

export interface ILeaderboardRequestedUser {
	id: string;
	platform: string;
	username: string;
}

export interface ILeaderboardParticipantsPage {
	curr: number;
}

export interface ILeaderboardLoading {
	leaderboards: boolean;
	participants: boolean;
}

export interface ILeaderboardItem {
	id: string;
	organizationId: string;
	resourceId: string;
	url: string;
	title: string;
	description?: string;
	dates: {
		begin: Date | null;
		end: Date | null;
	};
	images: {
		logo: string | null;
		background: string | null;
	};
	gifts: ILeaderboardGift[];
	schedule: ILeadrboardItemSchedule[];
	specialPage: {
		title: string;
		content: TLexicalContent | null;
		isAvailable: boolean;
	};
}

export interface ILeadrboardItemSchedule {
	id: string;
	date: Date;
	items: ILeadrboardItemScheduleItem[];
}

export interface ILeadrboardItemScheduleItem {
	id: string;
	title: string;
	period: string;
	description: TLexicalContent;
}

export class LeaderboardModel {
	@observable
	protected $isInitialized = false;

	@observable
	protected $isLoading: ILeaderboardLoading = {
		leaderboards: false,
		participants: false,
	};

	@observable
	protected $leaderboards: ILeaderboardItem[] = [];

	@observable
	protected $currentLeaderboardId: ILeaderboardCurrent = {
		organizationId: '',
		resourceId: '',
	};

	protected readonly $currentLeaderboardGifts = new LeaderboardsGiftsModel(this);

	@observable
	protected $requestedUser: ILeaderboardRequestedUser = {
		id: '',
		platform: 'twitch',
		username: '',
	};

	@observable
	protected $participants: ILeaderboardParticipantEntry[] = [];

	@observable
	protected $participantsPage: number = 0;

	@observable
	protected $participantsPageSize = 100;

	@observable
	protected $participantsSize = 0;

	@computed
	public get isInitialized(): boolean {
		return !!this.$isInitialized;
	}

	@computed
	public get isLoading(): boolean {
		return Object.values(this.$isLoading).some((value) => !!value);
	}

	@computed
	public get currentLeaderboardId(): ILeaderboardCurrent {
		return { ...this.$currentLeaderboardId };
	}

	@computed
	public get currentLeaderboard(): ILeaderboardItem | null {
		return (
			this.$leaderboards.find(
				(candidate) =>
					candidate.organizationId === this.$currentLeaderboardId.organizationId &&
					candidate.resourceId === this.$currentLeaderboardId.resourceId
			) ?? null
		);
	}

	@computed
	public get currentLeaderboardGifts(): LeaderboardsGiftsModel {
		return this.$currentLeaderboardGifts;
	}

	@computed
	public get leaderboards(): ILeaderboardItem[] {
		return [...this.$leaderboards];
	}

	@computed
	public get participants(): ILeaderboardParticipant[] {
		return this.$participants.map((entry, index) => {
			const isRequestedUser = !!entry?.Requester || entry?.ID?.ID === this.$requestedUser.id;

			return {
				username: isRequestedUser ? this.$requestedUser.username || (entry?.Slug ?? '') : (entry?.Slug ?? ''),
				position: entry?.Position ?? index + 1,
				score: entry?.Score || 0,
				isRequestedUser,
			};
		});
	}

	@computed
	public get participantsSize(): number {
		return this.$participantsSize;
	}

	@computed
	public get participantsPagination(): { current: number; size: number; total: number } {
		return {
			current: this.$participantsPage,
			total: Math.ceil(this.$participantsSize / this.$participantsPageSize),
			size: this.$participantsPageSize,
		};
	}

	@computed
	public get requestedUserParticipant(): ILeaderboardParticipant | undefined {
		return this.getRequestedUserParticipant();
	}

	public constructor(public readonly app: App) {
		makeObservable(this);
	}

	public initialize(): this {
		this.$leaderboards = [];
		this.$participants = [];

		this.$loadLeaderboards().then(
			action(() => {
				const first = this.$leaderboards[0];

				if (!!first) {
					this.setCurrent({
						organizationId: first.organizationId,
						resourceId: first.resourceId,
					});
				}

				this.$isInitialized = true;
			})
		);

		return this;
	}

	@action
	protected $setParticipantsPage(page: number): this {
		this.$participantsPage = page;

		return this;
	}

	protected $setParticipantsSize = action((listSize: number): void => {
		this.$participantsSize = Number(listSize);
	});

	protected $updateParticipants = action((items: ILeaderboardListResponse['List']): void => {
		for (const item of items ?? []) {
			const entry: ILeaderboardParticipantEntry = {
				...item,
				Position: parseInt(item.Position, 10),
				Score: parseInt(item.Score ?? '0', 10),
				Requester: !!item.Requester,
			};

			if (!!entry.Requester) {
				this.$requestedUser.username = entry.Slug;
			}

			const index = entry.Position - 1;

			this.$participants[index] = entry;
		}
	});

	protected $setIsLoading = action((key: keyof ILeaderboardLoading, isLoading: boolean): void => {
		this.$isLoading[key] = isLoading;
	});

	protected $loadLeaderboards(): Promise<{ list: ILeaderboardItem[] }> {
		return this.app.api.crm
			.request<ICRMLeaderboardActiveResponse>('/leaderboards/active?depth=2', {
				method: 'GET',
			})
			.then(
				action((response) => {
					this.$leaderboards = (response.docs ?? []).map((doc) => ({
						id: doc.id,
						organizationId: doc.oid,
						resourceId: doc.resource,
						title: this.app.models.intl.getLocalizedValue(doc.title),
						description: this.app.models.intl.getLocalizedValue(doc.description),
						dates: {
							begin: !!doc.begin ? new Date(doc.begin) : null,
							end: !!doc.end ? new Date(doc.end) : null,
						},
						url: generatePath(LEADERBOARD_TABLE, {
							organizationId: doc.oid,
							resourceId: doc.resource,
						}),
						images: {
							logo: !!doc.logoImage?.url ? encodeURI(doc.logoImage?.url) : null,
							background: !!doc.backgroundImage?.url ? encodeURI(doc.backgroundImage?.url) : null,
						},
						gifts: doc.gifts.map((gift) => ({
							id: gift.id,
							items: gift.items.map((item) => ({
								id: item.id,
								title: this.app.models.intl.getLocalizedValue(item.gift.title),
								description: this.app.models.intl.getLocalizedValue(item.gift.desription),
								image: encodeURI(item.gift.image.url),
								amount: item.amount,
							})),
							position: [gift.positionFrom, gift.positionTo].filter((value) => !_isNil(value)) as [
								number,
								number?,
							],
						})),
						schedule: doc.schedule.map((sch) => ({
							id: sch.id,
							date: new Date(sch.date),
							items: sch.items.map((item) => ({
								id: item.id,
								title: item.activity,
								period: item.period,
								description: item.description,
							})),
						})),
						specialPage: {
							title: '',
							content: null,
							isAvailable: false,
						},
					}));

					this.$currentLeaderboardGifts.reset();
				})
			)
			.then(() => {
				return { list: [...this.$leaderboards] };
			});
	}

	@action
	public setParticipantsPageSize(pageSize: number): this {
		this.$participantsPageSize = pageSize;

		return this;
	}

	@action
	public setCurrent(current: Partial<ILeaderboardCurrent>): this {
		this.$participants = [];
		this.$participantsPage = 0;

		this.$currentLeaderboardId = {
			...this.$currentLeaderboardId,
			...current,
		};

		this.$currentLeaderboardGifts.reset().setData(this.currentLeaderboard?.gifts ?? []);

		this.app.api.crm
			.request<ICRMTextBlockResponse>(
				`/text-blocks/get-by-tbid/${['special-page', current.organizationId, current.resourceId].join('__')}`,
				{
					method: 'get',
				}
			)

			.then((response) => {
				if (!!response?.error) {
					return;
				}

				const index = this.$leaderboards.findIndex(
					(leaderboard) =>
						leaderboard.organizationId === current.organizationId &&
						leaderboard.resourceId === current.resourceId
				);

				if (index >= 0 && !!this.$leaderboards[index]) {
					runInAction(() => {
						const title =
							typeof response.title === 'string'
								? response.title
								: response.title[this.app.models.intl.locale];
						const content = response.content;

						(this.$leaderboards[index] as ILeaderboardItem).specialPage = {
							title,
							content: content ?? null,
							isAvailable: !!(title && content),
						};
					});
				}
			})
			.catch(console.error);

		return this;
	}

	@action
	public setRequestedUser(user: Partial<ILeaderboardRequestedUser>): this {
		this.$requestedUser = {
			id: user.id ?? this.$requestedUser.id,
			platform: user.platform ?? this.$requestedUser.platform,
			username: user.username ?? this.$requestedUser.username,
		};

		return this;
	}

	public getRequestedUserParticipant(): ILeaderboardParticipant | undefined {
		return this.participants.find((item) => item.isRequestedUser);
	}

	public async loadParticipants(page?: number) {
		return !!this.$requestedUser.id && !page
			? this.$loadParticipantsPageWithUser()
			: this.$loadParticipantsPage(page ?? 1);
	}

	protected async $loadParticipantsPage(page?: number): Promise<{ list: ILeaderboardParticipant[] }> {
		this.$setIsLoading('participants', true);

		page = page ?? this.$participantsPage + 1;

		return this.app.api.leaderboardPublic
			.request<ILeaderboardListResponse>('/List', {
				method: 'POST',
				parameters: {
					ID: {
						OrganizationID: this.currentLeaderboardId.organizationId,
						ResourceID: this.currentLeaderboardId.resourceId,
					},
					Page: page,
					PageSize: this.$participantsPageSize,
				},
			})
			.then((response) => {
				this.$updateParticipants(response.List);
				this.$setParticipantsSize(response.Total);
				this.$setParticipantsPage(page);

				return {
					list: this.participants,
				};
			})
			.finally(() => {
				this.$setIsLoading('participants', false);
			});
	}

	protected async $loadParticipantsPageWithUser(): Promise<{ list: ILeaderboardParticipant[] }> {
		this.$setIsLoading('participants', true);

		if (!this.$requestedUser.id) {
			return Promise.resolve({ list: [] });
		}

		return this.app.api.leaderboardPublic
			.request<ILeaderboardListResponse>('/Show', {
				method: 'POST',
				parameters: {
					ID: {
						OrganizationID: this.currentLeaderboardId.organizationId,
						ResourceID: this.currentLeaderboardId.resourceId,
					},
					UserID: {
						ID: this.$requestedUser.id,
						Platform: this.$requestedUser.platform,
					},
					PageSize: this.$participantsPageSize,
				},
			})
			.then((response) => {
				this.$updateParticipants(response.List);

				const index = this.participants.findIndex((item) => item.isRequestedUser);

				if (index < 0) {
					return this.$loadParticipantsPage(1);
				}

				this.$setParticipantsPage(Math.ceil(index / this.$participantsPageSize));
				this.$setParticipantsSize(response.Total);

				return {
					list: this.participants,
				};
			})
			.finally(() => {
				this.$setIsLoading('participants', false);
			});
	}
}

export * from './gifts';
